import { Box, SxProps } from '@mui/system';
import { Upload as FileUploadIcon } from '@octanner/prism-icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import {
  tannerBlue,
  tannerGray,
  tannerSemantic,
} from '../ThemeProvider/colors';
import Typography from '../Typography';
import {
  DownloadButton,
  HelperText,
  HelpTextButtonContainer,
  SelectButtonText,
} from './styles';

const hasInvalidFileExtensions = (
  files: FileList,
  supportedExtensions: string[],
): boolean =>
  Object.values(files).some((file) => {
    const ext = file.name.split('.').slice(-1)[0];
    return !supportedExtensions.includes(ext);
  });

type Variant = 'sm' | 'md';
type ErrorType = 'invalid-extension' | 'multiple-files';

type Props = {
  onChange: (files: FileList) => void;
  supportedExtensions: string[];
  'data-testid'?: string;
  variant?: Variant;
  sampleFileText?: string;
  onSampleFileClick?: () => void;
  multiple?: boolean;
  onError?: (type: ErrorType) => void;
  error?: boolean;
  helperText?: string;
};

type VariantStyles = { [key in Variant]: SxProps };

const rootVariantStyles: VariantStyles = {
  sm: { display: 'flex', flexDirection: 'row', height: 56, gridGap: 16 },
  md: {
    display: 'flex',
    flexDirection: 'column',
    height: 128,
    justifyContent: 'center',
    gridGap: 6,
  },
};

const dragTextStyles: VariantStyles = {
  sm: { flex: '1 1 auto' },
  md: {
    textAlign: 'center',
  },
};

const FileUpload: React.FC<Props> = ({
  onChange,
  supportedExtensions,
  'data-testid': testid,
  variant = 'sm',
  sampleFileText,
  onSampleFileClick,
  multiple = false,
  helperText,
  error = false,
  onError,
}) => {
  const [inputRef, setInputRef] = useState<HTMLInputElement>();
  const [formRef, setFormRef] = useState<HTMLFormElement>();
  const { t } = useTranslation();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!inputRef) return;
    inputRef.click();
  };

  const stopDefaults = (e: React.DragEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleChange = (files?: FileList) => {
    if (!files || !files.length) return;
    if (hasInvalidFileExtensions(files, supportedExtensions)) {
      onError?.('invalid-extension');
      return;
    }
    if (!multiple && files.length > 1) {
      onError?.('multiple-files');
      return;
    }
    onChange(files);
  };

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    handleChange(e.target.files);
    if (!formRef) return;
    formRef.reset();
  };

  const handleDrop = (e: React.DragEvent<HTMLElement>) => {
    stopDefaults(e);
    const files = e.dataTransfer.files;
    handleChange(files);
  };

  const iconSize = 16;

  return (
    <Box data-testid={testid}>
      <Box
        component="section"
        onClick={handleClick}
        sx={{
          ...rootVariantStyles[variant],
          px: 5,
          alignItems: 'center',
          width: '100%',
          border: `2px dashed ${tannerGray['200']}`,
          borderRadius: '8px',
          backgroundColor: tannerGray['50'],
          transitionProperty: 'color,background-color,border-color',
          transitionDelay: '0ms',
          transitionDuration: '250ms',
          transitionTimingFunction: 'ease',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: tannerGray[100],
            borderColor: tannerGray[300],
            ...(error && { borderColor: tannerSemantic.error.color }),
          },
          '&:active': {
            backgroundColor: tannerGray['200'],
            borderColor: tannerGray['400'],
            ...(error && { borderColor: tannerSemantic.error.color }),
          },
          ...(error && { borderColor: tannerSemantic.error.color }),
        }}
        onDragOver={stopDefaults}
        onDrop={handleDrop}
      >
        <FileUploadIcon
          sx={{
            color: tannerBlue['500'],
            height: iconSize,
            width: iconSize,
            flex: '0 0 auto',
          }}
        />
        <Box sx={dragTextStyles[variant]}>
          <Typography>
            {t('prism-header:drag-files-here', 'Drag files here')}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: tannerGray['700'] }}
            data-testid="supported-extensions"
          >
            {supportedExtensions.map((ext) => '.' + ext).join(', ')}
          </Typography>
        </Box>
        <Button variant="text" sx={{ flex: '0 0 auto' }} onClick={handleClick}>
          <SelectButtonText>
            {t('prism-header:select-files', 'Select files')}
          </SelectButtonText>
          <form ref={(e) => setFormRef(e ?? undefined)}>
            <input
              ref={(e) => setInputRef(e ?? undefined)}
              hidden
              multiple={multiple}
              type="file"
              onChange={handleSelect}
              data-testid={testid ? `${testid}:input` : undefined}
            />
          </form>
        </Button>
      </Box>
      <HelpTextButtonContainer>
        {helperText && (
          <HelperText error={error} data-testid="helper-text">
            {helperText}
          </HelperText>
        )}
        {sampleFileText && (
          <DownloadButton
            size="small"
            variant="text"
            color="secondary"
            buttonType="secondary"
            data-testid="download-button"
            onClick={onSampleFileClick}
          >
            {sampleFileText}
          </DownloadButton>
        )}
      </HelpTextButtonContainer>
    </Box>
  );
};

export default FileUpload;
