import styled from '@emotion/styled';
import Typography from '../Typography';
import { Box } from '@mui/system';
import Button from '../Button';
import FormHelperText from '../FormHelperText';
import { tannerBlue } from '../ThemeProvider/colors';

export const DownloadButton = styled(Button)`
  white-space: nowrap;
  min-width: max-content;
  margin-left: auto;
`;

export const HelpTextButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 2px;
  gap: 2px;
`;

export const HelperText = styled(FormHelperText)`
  margin-left: 0px;
`;

export const SelectButtonText = styled(Typography)`
  font-weight: 600;
  color: ${tannerBlue['500']};
`;
